import React from "react";

import BobVideo from "../images/meetBob/video.mp4";
import DanaVideo from "../images/meetDana/video.mp4";

export const VideoBob = () => {
  return (
    <video
      width={815}
      className="block w-full"
      muted
      autoPlay
      loop
      src={BobVideo}
    />
  );
};

export const VideoDana = () => {
  return (
    <video
      width={815}
      className="block w-full"
      muted
      autoPlay
      loop
      src={DanaVideo}
    />
  );
};
