import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import { ArrowInclined } from "../atoms/Icons"
import Layout from "../components/layout"
import { VideoBob } from "../atoms/video"

const MeetBobPage = () => {
  return (
    <>
      {/* Mobile */}
      <div className="lg:hidden">
        <Layout>
          <h1 className="mobile-header lg:desktop-header">Meet Bob</h1>
          <p className="mobile-body pt-4">
            BBQ Bob Maxey was born in Los Angeles, California. <br />
            He was raised on a surfboard in Hermosa Beach, where he excelled at
            art while quite young, Picasso Jr if you will. In high school he won
            The Scholastic Magazine “Gold Key” for Art and was awarded a full
            scholarship to attend The California Academy of Art, in Oakland,
            California. His mother had plans for him to be an Art Director for a
            magazine, but as it was the 1960’s, he Tuned IN and Dropped OUT,
            dodging his way to Australia. <br />
            His passion for art equals that of his love for Music, where he
            still sees himself as Keith Richards. He has performed with many
            different bands, including Bo Diddly, The World Famous Bobo’s and is
            a fool for Jazz.
            <br />
            He plays guitar, piano, harmonica, drums and various stringed
            instruments. Music trivia is his forte. He is the one in the skirt
            in our band, <strong> Frida’s Eyebrow.</strong>
            <br />
            <br />
          </p>
          <p>
            His focus on painting began with rock Mandalas collected from his
            morning beach walks. Each day he carried home a knapsack full of
            rocks and began exploring symbolic forms, with a tribal feel to
            them.
            <br /> He plays with acrylics and pencil prolifically creating
            different “series” exploring his ideas. Nature, Monsters, and
            Portraiture speak volumes to him.
          </p>
          <br />
          <p>
            His mantra:
            <span className="inline mobile-body-italic">
              {" "}
              Celebrate Dream Time.
            </span>
            <br /> He is a Beatle’s man.
          </p>
          <br />
          <div className="py-2 max-w-screen overflow-x-scroll overscroll-x-contain lg:overscroll-x-hidden  pl-1 flex gap-4 items-center">
            <StaticImage
              height={275}
              src="../images/meetBob/1.png"
              alt="Bob art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={275}
              src="../images/meetBob/2.png"
              alt="Bob art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={275}
              src="../images/meetBob/3.png"
              alt="Bob watching tv"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={275}
              src="../images/meetBob/4.png"
              alt="Bob fishing"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={275}
              src="../images/meetBob/5.png"
              alt="Bob haircut"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={275}
              src="../images/meetBob/6.png"
              alt="Bob fishing"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={275}
              src="../images/meetBob/7.png"
              alt="Bob longboard"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={275}
              src="../images/meetBob/8.png"
              alt="Bob fiching"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={275}
              src="../images/meetBob/9.png"
              alt="Bob playing piano"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={275}
              src="../images/meetBob/new.JPG"
              alt="Bob playing piano"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
          </div>
          <Link className="flex items-center font-bold my-4" to="/bob-gallery">
            <ArrowInclined />
            Visit Bob's Art Gallery
          </Link>
        </Layout>
      </div>

      {/* Desktop */}
      <div className="hidden lg:block">
        <Layout>
          <h1 className="mobile-header lg:desktop-header">Meet Bob</h1>
          <div className="flex">
            <div className="max-w-md desktop-body">
              <p className="">
                BBQ Bob Maxey was born in Los Angeles, California. <br />
                He was raised on a surfboard in Hermosa Beach, where he excelled
                at art while quite young, Picasso Jr if you will. In high school
                he won The Scholastic Magazine “Gold Key” for Art and was
                awarded a full scholarship to attend The California Academy of
                Art, in Oakland, California. His mother had plans for him to be
                an Art Director for a magazine, but as it was the 1960’s, he
                Tuned IN and Dropped OUT, dodging his way to Australia. <br />
                His passion for art equals that of his love for Music, where he
                still sees himself as Keith Richards. He has performed with many
                different bands, including Bo Diddly, The World Famous Bobo’s
                and is a fool for Jazz.
                <br />
                He plays guitar, piano, harmonica, drums and various stringed
                instruments. Music trivia is his forte. He is the one in the
                skirt in our band, <strong> Frida’s Eyebrow.</strong>
                <br />
                <br />
              </p>
              <p>
                His focus on painting began with rock Mandalas collected from
                his morning beach walks. Each day he carried home a knapsack
                full of rocks and began exploring symbolic forms, with a tribal
                feel to them.
                <br /> He plays with acrylics and pencil prolifically creating
                different “series” exploring his ideas. Nature, Monsters, and
                Portraiture speak volumes to him.
              </p>
              <br />
              <p>
                His mantra:
                <span className="inline mobile-body-italic">
                  {" "}
                  Celebrate Dream Time.
                </span>
                <br /> He is a Beatle’s man.
              </p>
              <Link
                className="py-2 flex items-center font-bold my-4"
                to="/bob-gallery"
              >
                <ArrowInclined />
                Visit Bob's Art Gallery
              </Link>
            </div>
            <div className="w-3/4 max-w-screen  flex gap-2 xl:gap-4 2xl:gap-8 flex-col ml-2 2xl:mx-0 pl-8">
              <div className="flex gap-2 xl:gap-4 overflow-x-scroll overscroll-x-contain ">
                <StaticImage
                  height={311}
                  src="../images/meetBob/4.png"
                  alt="Bob fishing"
                  placeholder="tracedSVG"
                  className="flex-shrink-0"
                />
                <StaticImage
                  height={311}
                  src="../images/meetBob/3.png"
                  alt="Bob watching tv"
                  placeholder="tracedSVG"
                  className="flex-shrink-0 ml-auto"
                />
              </div>
              <div className="flex gap-2 xl:gap-4 2xl:gap-8">
                <StaticImage
                  height={558}
                  width={386}
                  src="../images/meetBob/2.png"
                  alt="Bob art show"
                  placeholder="tracedSVG"
                  className="flex-shrink-0"
                />
                <div className="flex-col ">
                  <div className="overflow-x-scroll overscroll-x-contain pl-1 flex gap-2 xl:gap-4 2xl:gap-8 items-center">
                    <StaticImage
                      height={414}
                      width={267}
                      src="../images/meetBob/1.png"
                      alt="Bob art"
                      placeholder="tracedSVG"
                      className="flex-shrink-0"
                    />
                    <StaticImage
                      height={414}
                      width={267}
                      src="../images/meetBob/5.png"
                      alt="Bob haircut"
                      placeholder="tracedSVG"
                      className="flex-shrink-0"
                    />
                    <StaticImage
                      height={414}
                      width={267}
                      src="../images/meetBob/6.png"
                      alt="Bob fishing"
                      placeholder="tracedSVG"
                      className="flex-shrink-0"
                    />
                    <StaticImage
                      height={414}
                      width={267}
                      src="../images/meetBob/7.png"
                      alt="Bob longboard"
                      placeholder="tracedSVG"
                      className="flex-shrink-0"
                    />
                    <StaticImage
                      height={414}
                      width={267}
                      src="../images/meetBob/8.png"
                      alt="Bob fishing 2"
                      placeholder="tracedSVG"
                      className="flex-shrink-0"
                    />
                    <StaticImage
                      height={414}
                      width={267}
                      src="../images/meetBob/9.png"
                      alt="Bob playing piano"
                      placeholder="tracedSVG"
                      className="flex-shrink-0"
                    />
                    <StaticImage
                      height={414}
                      width={267}
                      src="../images/meetBob/new.JPG"
                      alt="Bob playing piano"
                      placeholder="tracedSVG"
                      className="flex-shrink-0"
                    />
                  </div>
                  <p className="desktop-quote text-center pt-4">
                    “I have no idea what I’m doin’, I have never seen me”
                  </p>
                  <br />
                  <p className="desktop-body text-center">Mose Allison</p>
                </div>
              </div>
              {/* <StaticImage
                height={539}
                width={815}
                src="../images/meetBob/video.mp4"
                alt="Bob art show"
                placeholder="tracedSVG"
                className="flex-shrink-0"
              /> */}
              <VideoBob />
            </div>
          </div>
        </Layout>
      </div>
    </>
  )
}

export default MeetBobPage
